<template lang="pug">
    v-container
        iframe(:src="pdfUrl" width='100%')
</template>
<script>

export default {
    data() {
        return{
        pdfUrl: '/pdf/DPA.pdf'
        }
    }
}
</script>